<template>
  <div>
    <div
      v-if="flag === 1"
      class="explainSpace"
    >
      <div class="featureSpace text-center">
        <h2 class="title_">
          「学びたい人(受講者)」「働きたい人(求職者)」ができること
        </h2>
        <div class="subtitle_ mt-2">
          好きな学びが仕事になるしあわせも、<br>なりたい自分になるためのスキルアップも。
        </div>
        <div class="explain_ mt-2">
          入口は違っても、skillismでは学ぶと働くが同じフィールドで繋がっています。
        </div>
        <div class="explain_ mt-2">
          すぐに役立つ学び、スキルを活かした 仕事探しができるだけでなく、<br>その学びが未来の仕事になるしあわせもskillismの目指すところです。
        </div>
        <div class="mt-3 mb-3">
          <b-row>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="Edit3Icon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  勉強する・経験する<br>
                  <small><b>学習から探す</b></small>
                </div>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="BarChartIcon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  就職する・転職する<br>
                  <small><b>求人情報から探す</b></small>
                </div>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="CompassIcon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  参加する・応募する<br>
                  <small><b>イベント・コンテストから探す</b></small>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="spaceContent">
          <h2 class="title_ mb-3 text-center">
            会員登録をして、気になるカテゴリからコンテンツを検索。<br>好きなコンテンツを予約すれば、新しい学びのスタートです。
          </h2>
          <b-row>
            <b-col lg="8">
              <div class="explain_">
                会員登録、一部の学習コンテンツ・求人情報・イベント情報の閲覧は無料です。
              </div>
              <div class="explain_ mt-1">
                有料コンテンツでは専門的な講師による動画やレッスンも豊富にそろえ、メッセージによる質疑応答のほか、コミュニケーションによる経験学習を採用してスキルの定着を高めています。
              </div>
              <div class="explain_ mt-1">
                また学習履歴、講師による評価・フィードバック、参加コンテストの結果はダッシュボードに蓄積、オンライン履歴書にも反映され、求人企業が求める人材としてのアピールとなります。
              </div>
            </b-col>
            <b-col>
              <b-img
                src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fstil-Mw9KxYkqsnk-unsplash%20(1).jpg?alt=media&token=1e589964-a94e-4400-b6d3-656216dda13f"
                blank-color="#ccc"
                alt="placeholder"
                class="imgStyle_"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div
      v-if="flag === 2"
      class="explainSpace"
    >
      <div class="featureSpace text-center">
        <h2 class="title_">
          「教えたい人（講師）」ができること
        </h2>
        <div class="subtitle_ mt-2">
          あなたのスキルを、世界の誰かが待っています。
        </div>
        <div class="explain_ mt-2">
          自分のやり方で、今いる場所から、世界中のskillismの受講者へ学習コンテンツを配信。
        </div>
        <div class="explain_ mt-2">
          学生でも社会人でも引退後でも、あなたのスキルを待っている人がいます。
        </div>
        <div class="mt-3 mb-3">
          <b-row>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="Edit3Icon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  レッスンや動画を<br>計画・登録すること
                </div>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="BarChartIcon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  レッスンや動画の管理や<br>スケジュールの調整すること
                </div>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="CompassIcon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  レッスンや動画によって得た<br>コインを精算すること
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="spaceContent">
          <h2 class="title_ mb-3 text-center">
            skillismは教えたい人・学びたい人が出会えるプラットフォームです。
          </h2>
          <b-row>
            <b-col lg="8">
              <div class="explain_">
                プロフィールの登録、審査の承認後、コンテンツを作りましょう。レッスンやセミナーといったサービスのタイプ、金額、最少人数、スケジュールなどを決めて打ち込むと、学びたい人の検索欄にアップされます。
              </div>
              <div class="explain_ mt-1">
                コンテンツの内容、教材、動画制作も自由にご用意ください。講師の経験が未経験だとしても、経験学習機能により教える上での新たな発見や刺激になるかもしれません。
              </div>
              <div class="explain_ mt-1">
                受講料はサイト内のコイン通貨で行い、受講者からの徴収、清算、振込までをskillismが管理。安心して受講者とのコミュニケーションを楽しんでください。
              </div>
            </b-col>
            <b-col>
              <b-img
                src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fstil-flRm0z3MEoA-unsplash.jpg?alt=media&token=ec9f6576-80e3-4807-a498-1c3da779807c"
                blank-color="#ccc"
                alt="placeholder"
                class="imgStyle_"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div
      v-if="flag === 3"
      class="explainSpace"
    >
      <div class="featureSpace text-center">
        <h2 class="title_">
          「働いてほしい事業者（求人企業）」ができること
        </h2>
        <div class="subtitle_ mt-2">
          スキルを基準に、世界中の人材を探せる、出会える。
        </div>
        <div class="explain_ mt-2">
          求める人材情報は世界に発信されます。働きたい人の履歴書のほかに、サイト内での学習履歴・講師評価を確認でき、求人企業が求める必要なスキルを持つ人材採用の確実性を高めます。
        </div>
        <div class="explain_ mt-2">
          また採用後も継続的な学びによるスキルの向上を、skillismで行えます。
        </div>
        <div class="mt-3 mb-3">
          <b-row>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="Edit3Icon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  求人情報を<br>登録・募集すること
                </div>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="BarChartIcon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  求職者の履歴書を検索<br>求職者にスカウトできること
                </div>
              </div>
            </b-col>
            <b-col lg="4">
              <div class="text-center mt-2">
                <feather-icon
                  icon="CompassIcon"
                  size="55"
                />
                <div
                  class="userLabel_ mt-1"
                >
                  イベントやコンテストを<br>計画・募集すること
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="spaceContent">
          <h2 class="title_ mb-3 text-center">
            求人情報の掲載、働きたい人リストの閲覧（一部制限あり）は、無料プランでもご利用できます。
          </h2>
          <b-row>
            <b-col lg="8">
              <div class="explain_">
                有料プランでは、気になる人材の詳細情報や直接のアプローチ機能（スカウト機能）、求人ごとの掲載・アクセス状況を数値で確認し、改善にお役立ていただけます。求職者の履歴書では、skillism内で学んだ学習履歴や講師評価を確認でき、求めるスキルに応じた最適な人材が探せます。
              </div>
              <div class="explain_ mt-1">
                また企業が主催者となってイベントやコンテストを開催することで、知識の理解度・応用力などを判断し、応募者の中から有能な人材をリクルートすることもできます。
              </div>
              <div class="explain_ mt-1">
                国や環境に縛られない人材活用は、多様な働き方、働く機会の平等性、格差の是正といった企業に求められる社会的責任の一旦にも寄与するはずです。skillismと一緒に、よりよい社会の実現に向けて歩みませんか。
              </div>
            </b-col>
            <b-col>
              <b-img
                src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fchristina-wocintechchat-com-faEfWCdOKIg-unsplash.jpg?alt=media&token=d916cc5f-e053-4a66-b33d-498e98c871e3"
                blank-color="#ccc"
                alt="placeholder"
                class="imgStyle_"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BImg, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
  },
  directives: {
  },
  props: {
    flag: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.imgStyle_ {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.explainSpace {
  width: 70%;
  margin: 5% auto;
}
.title_ {
  font-size: 2rem;
  font-weight: 600;
}
.subtitle_ {
  font-size: 1.8rem;
  font-weight: 600;
  /* margin: 5% 0 0 0; */
}
.explain_ {
  font-size: 1.4rem;
  font-weight: 600;
}
.featureSpace {
  margin: 5% 0 10% 0;
}
.userLabel_ {
  font-size: 1.2rem;
  font-weight: 600;
}
.border_ {
  border-bottom: solid 3px;
  width: 80%;
}
.spaceContent {
  margin: 15% 0;
}
</style>
