<template>
  <div>
    <!-- <b-img
      src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fnordwood-themes-kRNZiGKtz48-unsplash.jpg?alt=media&token=ecc20eb5-e7b4-4836-883b-4fb6ed544f07"
      blank-color="#ccc"
      alt="placeholder"
      class="imgStyle_"
    /> -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url('https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fnordwood-themes-kRNZiGKtz48-unsplash.jpg?alt=media&token=ecc20eb5-e7b4-4836-883b-4fb6ed544f07')`}"
      >
        <b-card-body class="text-center">
          <h2 class="title_">
            サービスの紹介
          </h2>
          <b-card-text class="mb-2">
            skillismのサービスについてご説明します。
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>
    <div class="explainSpace">
      <div class="featureSpace">
        <div class="subtitle_ text-center">
          「学ぶ」「働く」「教える」ための、<br>出会いが見つかるオンラインサービスです。
        </div>
        <div class="explain_ mt-2 text-center">
          専門技術の講習から学会セミナー、趣味のレッスンまで。
        </div>
        <div class="explain_ text-center">
          多種多様な学習コンテンツと世界中の講師陣、スキルを活かせる求人情報をご用意して、 <br>人生の活力となる出会いを提供します。
        </div>
        <div class="mt-3 mb-3">
          <b-row>
            <b-col lg="4">
              <a
                @click="switchFlab(1)"
              >
                <div class="text-center mt-2">
                  <feather-icon
                    icon="UsersIcon"
                    size="45"
                    :class="flag === 1 ? 'text-primary': ''"
                  />
                  <div
                    class="userLabel_ mt-1"
                    :class="flag === 1 ? 'text-primary': ''"
                  >
                    <span
                      :class="flag === 1 ? 'underLine': ''"
                    >
                      受講生・求職者
                    </span>
                  </div>
                </div>
              </a>
            </b-col>
            <b-col lg="4">
              <a
                @click="switchFlab(2)"
              >
                <div class="text-center mt-2">
                  <feather-icon
                    icon="AwardIcon"
                    size="45"
                    :class="flag === 2 ? 'text-primary': ''"
                  />
                  <div
                    class="userLabel_ mt-1"
                    :class="flag === 2 ? 'text-primary': ''"
                  >
                    <span
                      :class="flag === 2 ? 'underLine': ''"
                    >
                      講師
                    </span>
                  </div>
                </div>
              </a>
            </b-col>
            <b-col lg="4">
              <a
                @click="switchFlab(3)"
              >
                <div class="text-center mt-2">
                  <feather-icon
                    icon="ColumnsIcon"
                    size="45"
                    :class="flag === 3 ? 'text-primary': ''"
                  />
                  <div
                    class="userLabel_ mt-1"
                    :class="flag === 3 ? 'text-primary': ''"
                  >
                    <span
                      :class="flag === 3 ? 'underLine': ''"
                    >
                      求人企業
                    </span>
                  </div>
                </div>
              </a>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <hr>
    <IntroductionAbility :flag="flag" />
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BCardText,
} from 'bootstrap-vue'
import IntroductionAbility from '@/components/basic/about/IntroductionAbility.vue'

export default {
  components: {
    BRow,
    BCol,
    // BImg,
    BCard,
    BCardBody,
    BCardText,
    IntroductionAbility,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
      flag: 1,
    }
  },
  mounted() {
    switch (this.$route.query.type) {
      case 'student':
        this.flag = 1
        break
      case 'teacher':
        this.flag = 2
        break
      case 'company':
        this.flag = 3
        break
      default:
        this.flag = 1
        break
    }
  },
  methods: {
    switchFlab(val) {
      this.flag = val
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>

<style scoped>
.imgStyle_ {
  width: 110%;
  height: 320px;
  object-fit: cover;
  margin: -5% -5% 0 -5%;
}
.explainSpace {
  width: 70%;
  margin: 5% auto;
}
.title_ {
  font-size: 2rem;
  font-weight: 600;
}
.subtitle_ {
  font-size: 2.5rem;
  font-weight: 600;
  /* margin: 5% 0 0 0; */
}
.explain_ {
  font-size: 1.4rem;
  font-weight: 600;
}
.featureSpace {
  margin: 5% 0 10% 0;
}
.userLabel_ {
  font-size: 1.2rem;
  font-weight: 600;
}
.border_ {
  border-bottom: solid 3px;
  width: 80%;
}
.underLine {
  border-bottom: solid 3px rgb(130, 110, 245);
  padding: 0% 10% 4% 10%;
}
</style>
